import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import newsList from "@/common/news";
import linksList from "@/common/links";

export default {
  name: "App",
  components: {
    MainHeader,
    MainFooter
  },
  data() {
    return {
      newsList: newsList,
      roleList: [{
        name: "DIMOO占星师",
        img: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_DM_3.jpg",
        title: "DIMOO占星师",
        desc: "<span>不知何时起就生活在云鲸岛上的存在，作为云鲸岛的占星师，拥有强大而神秘的力量。能够推知过去、预测未来，占星师将作为绘梦师们的引导师。</span>",
        headerBg: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_DM_1.png",
        header: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_DM_2.png",
      }, {
        name: "LABUBU攀登者",
        img: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_LB_3.jpg",
        title: "LABUBU攀登者",
        desc: "<span>技能：镐劈山河</span><br>" +
          "<span>描述：使用LABUBU攀登者采矿时，可以连续掉落多次矿石，极大提升采矿效率；</span>",
        headerBg: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_LB_1.png",
        header: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_LB_2.png",
      }, {
        name: "MOLLY你变得特别甜",
        img: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_ML_3.jpg",
        title: "MOLLY你变得特别甜",
        desc: "<span>技能：西瓜滑板</span><br>" +
          "<span>描述：持续奔跑可进入西瓜滑板状态，移动速度提升；</span>",
        headerBg: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_ML_1.png",
        header: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_ML_2.png",
      }, {
        name: "PUCKY小鸭宝宝",
        img: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_BQ_3.jpg",
        title: "PUCKY小鸭宝宝",
        desc: "<span>技能：鸭鸭水壶</span><br>" +
          "<span>描述：可以同时浇多块农田，并减少农作物的成熟时间；</span>",
        headerBg: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_BQ_1.png",
        header: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_BQ_2.png",
      }, {
        name: "SKULLPANDA喜剧演员",
        img: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_SP_3.jpg",
        title: "SKULLPANDA喜剧演员",
        desc: "<span>技能：木偶助手</span><br>" +
          "<span>描述：使用木偶代替自己工作，砍树时有机会不消耗自身体力；</span>",
        headerBg: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_SP_1.png",
        header: "https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshi_SP_2.png",
      }],
      roleIndex: 0,
      roleTicker: null,
      carouselList: [
        'https://cdn-game.paquhuyu.com/website/static/others/carousel_1.jpg',
        'https://cdn-game.paquhuyu.com/website/static/others/carousel_2.jpg',
        'https://cdn-game.paquhuyu.com/website/static/others/carousel_3.jpg',
        'https://cdn-game.paquhuyu.com/website/static/others/carousel_4.jpg',
        'https://cdn-game.paquhuyu.com/website/static/others/carousel_5.jpg'
      ],
      carouselOnTab: 'https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshikuang_3.png',
      carouselOffTab: 'https://cdn-game.paquhuyu.com/website/static/pc/wangye_zhanshikuang_4.png',
      carouselIndex: 0,
      carouselTicker: null,
      value: 0,
      subscribed: false,
      subscribeCount: 10000000,
      videoDialog: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          // type: "video/mp4", // 类型
          type: 'application/x-mpegURL',
          src: 'https://cdn-game.paquhuyu.com/website/static/video/pv1/dream.m3u8' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      rightAgeDialog: false,
      links: linksList
    }
  },
  mounted() {
    // this.changeRole(0);
    // this.changeCarousel(0);
    // 暂时不获取
    // this.$refs.mainHeader.getSubscribeInfo();
    window.addEventListener("resize", this.onrResize);
    this.init();
  },
  methods: {
    init() {
      let searchParams = new URLSearchParams(window.location.search);
      let origin = searchParams.get("channel") || '';
      localStorage.setItem("channel", origin);
    },
    initRoleTicker() {
      clearInterval(this.roleTicker);
      this.roleTicker = setInterval(this.changeRole.bind(this), 5000);
    },
    initCarouselTicker() {
      clearInterval(this.carouselTicker);
      this.carouselTicker = setInterval(this.changeCarousel.bind(this), 5000);
    },
    changeRole(index) {
      if (index !== undefined) {
        this.roleIndex = index;
        this.initRoleTicker();
      } else {
        this.roleIndex = (++this.roleIndex) % this.roleList.length;
      }
      this.calcRoleStar();
    },
    onrResize() {
      // this.calcRoleStar();
    },
    calcRoleStar() {
      let roleTabBgEl = document.getElementById('roleTabBg');
      let roleTabStarEl = document.getElementById('roleTabStar');
      let roleTabImgEls = document.getElementsByClassName('role-tab-img');
      let imgWidth = roleTabImgEls[0].clientWidth;
      roleTabBgEl.style.width = imgWidth + 'px';
      roleTabStarEl.style.left = 60 + imgWidth * (this.roleIndex + 0.5) - roleTabStarEl.clientWidth / 2 + 'px';
      roleTabBgEl.style.left = 60 + imgWidth * (this.roleIndex) + 'px';
    },
    changeCarousel(index) {
      if (index !== undefined) {
        this.carouselIndex = index;
        this.initCarouselTicker(index);
      } else {
        this.carouselIndex = (++this.carouselIndex) % this.carouselList.length;
      }
    },
    async subscribe() {
      await this.$refs.mainHeader.subscribe();
    },
    async onUserInfoChange(subscribed, subscribeCount) {
      this.subscribed = subscribed;
      this.subscribeCount = subscribeCount;
    },
    playVideo() {
      let self = this;
      self.videoDialog = true;
      process.nextTick(() => {
        self.$refs['videoPlayer'].player.play();
      });
    },
    closeVideo() {
      let self = this;
      process.nextTick(() => {
        self.$refs['videoPlayer'].player.pause();
      });
      return true;
    },
    closeVideoDialog() {
      this.videoDialog = false;
    },
    initRoleBanner() {
      // let self = this;
      // let roleBannerEL = self.$refs.roleBanner;
    },
    rightAgeNotice() {
      this.rightAgeDialog = true;
    },
    closeRightAge() {
      this.rightAgeDialog = false;
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}