import Vue from 'vue'
import Index from './Index.vue';
import axios from 'axios';
import Van from 'vant';
import { Toast, Dialog } from 'vant';
import 'vant/lib/index.css';
import '@/common/style/thememin.styl';
import '@/common/style/rest.styl';
import '@/common/style/base.styl';
import '@/common/style/website.styl';
import '@/common/style/responsive.styl';
import Loading from '@/components/loading.js'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import '@/assets/css/bootstrap.min.css'
// import '@/assets/css/default-css.css'
// import '@/assets/css/styles.css'
// import '@/assets/css/responsive.css'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

Vue.use(Van);
Vue.use(iView);
Vue.use(Loading)
Vue.use(VideoPlayer);
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
//全局引入axios
axios.defaults.withCredentials = true;
Vue.prototype.$ajax = axios;
Vue.config.productionTip = false;

new Vue({
  render: h => h(Index),
}).$mount('#app');